import { JobStatus } from '@doc-abode/data-models';

export const isPostJobNotesRequired = (
    status: string,
    postJobNotesFeatureEnabled: boolean,
    isEdit: boolean,
): boolean => {
    if (!postJobNotesFeatureEnabled || !isEdit) {
        return false;
    }

    if (![JobStatus.COMPLETED].includes(status as JobStatus)) {
        return false;
    }

    return true;
};
