import moment from 'moment';

import { ReportAllJobsQuery } from '../../../__generated__/v2';
import { chartPalette, classificationsSortOrder } from './consts';
import { ChartData, Granularity } from './types';

export const prepareJobsData = (
    data: ReportAllJobsQuery['reportAllJobs'],
    minDate: Date,
    maxDate: Date,
    granularity: Granularity,
): ChartData => {
    let format: string;
    let unit: moment.unitOfTime.DurationConstructor;

    switch (granularity) {
        case 'daily':
            format = 'DD MMM YYYY';
            unit = 'day';
            break;
        case 'weekly':
            format = '[w]W GGGG';
            unit = 'week';
            break;
        case 'monthly':
            format = 'MMM YYYY';
            unit = 'month';
            break;
        default:
            format = 'DD MMM YYYY';
            unit = 'day';
    }

    const classificationGrouped = new Map<string, Map<string, number>>();

    data.forEach((job) => {
        if (!job.plannedDate) return;

        const classification = job.jobClassification || 'No Classification';
        const dateKey = moment(job.plannedDate).format(format);

        if (!classificationGrouped.has(classification)) {
            classificationGrouped.set(classification, new Map<string, number>());
        }

        const dateMap = classificationGrouped.get(classification)!;

        dateMap.set(dateKey, (dateMap.get(dateKey) ?? 0) + 1);
    });

    const labels: string[] = [];
    const current = moment(minDate).startOf(unit);
    const last = moment(maxDate).startOf(unit).subtract(1, unit);

    while (current.isBefore(last, unit) || current.isSame(last, unit)) {
        labels.push(current.format(format));
        current.add(1, unit);
    }

    const datasets = Array.from(classificationGrouped.entries())
        .sort((a, b) => {
            const aIndex = classificationsSortOrder.indexOf(a[0]);
            const bIndex = classificationsSortOrder.indexOf(b[0]);
            return aIndex - bIndex;
        })
        .map(([classification, dateMap]) => {
            const dataArr = labels.map((label) => dateMap.get(label) || 0);
            const index = classificationsSortOrder.indexOf(classification);

            return {
                label: classification,
                data: dataArr,
                backgroundColor: chartPalette[index],
                borderColor: chartPalette[index],
            };
        });

    return { labels, datasets };
};

export const prepareJobsPerServiceData = (
    data: ReportAllJobsQuery['reportAllJobs'],
    prop: 'service' | 'adminType',
): ChartData => {
    const valueCounts = new Map<string, number>();

    data.forEach((job) => {
        const isAdmin = job.jobClassification === 'Administrative';
        const value = job.serviceName || (prop === 'service' ? 'No Service' : 'No Admin Type');

        if ((prop === 'service' && !isAdmin) || (prop === 'adminType' && isAdmin)) {
            valueCounts.set(value, (valueCounts.get(value) ?? 0) + 1);
        }
    });

    const labels = Array.from(valueCounts.keys()).sort((a, b) => a.localeCompare(b));
    const counts = labels.map((label) => valueCounts.get(label) || 0);

    return {
        labels,
        datasets: [
            {
                label: 'Jobs per Service',
                data: counts,
                backgroundColor: chartPalette[0],
                borderColor: chartPalette[0],
            },
        ],
    };
};

export const prepareJobsByPathwayData = (data: ReportAllJobsQuery['reportAllJobs']): ChartData => {
    const pathwayMap = new Map<string, Map<string, number>>();
    const classificationSet = new Set<string>();

    data.filter((job) => job.jobClassification !== 'Administrative').forEach((job) => {
        const pathway = job.pathwayName || 'No Pathway';
        const classification = job.jobClassification || 'No Classification';

        classificationSet.add(classification);

        if (!pathwayMap.has(pathway)) {
            pathwayMap.set(pathway, new Map<string, number>());
        }

        const classificationMap = pathwayMap.get(pathway)!;

        classificationMap.set(classification, (classificationMap.get(classification) ?? 0) + 1);
    });

    const labels = Array.from(pathwayMap.keys()).sort((a, b) => a.localeCompare(b));
    const classifications = Array.from(classificationSet);

    classifications.sort((a, b) => {
        const aIndex = classificationsSortOrder.indexOf(a);
        const bIndex = classificationsSortOrder.indexOf(b);
        return aIndex - bIndex;
    });

    const datasets = classifications.map((classification) => {
        const dataArr = labels.map((label) => {
            const classMap = pathwayMap.get(label);
            return classMap ? classMap.get(classification) ?? 0 : 0;
        });
        const index = classificationsSortOrder.indexOf(classification);

        return {
            label: classification,
            data: dataArr,
            backgroundColor: chartPalette[index],
            borderColor: chartPalette[index],
        };
    });

    return { labels, datasets };
};
