export enum Granularity {
    daily = 'daily',
    weekly = 'weekly',
    monthly = 'monthly',
}

export enum TimeInterval {
    allTime = 'all time',
    today = 'today',
    yesterday = 'yesterday',
    thisWeek = 'this week',
    thisMonth = 'this month',
    thisYear = 'this year',
    lastWeek = 'last week',
    lastMonth = 'last month',
    last3Month = 'last 3 months',
    lastYear = 'last year',
    financialYear = 'financial year',
}

export type SinglePropsType = 'interval' | 'granularity';
export type MultiPropsType = 'classifications' | 'services' | 'pathways';

export type ChartData = {
    labels: string[];
    datasets: Array<{
        label: string;
        data: number[];
        backgroundColor: string;
        borderColor: string;
    }>;
};

export type StatsType = {
    initials: number;
    directs: number;
    indirects: number;
    administratives: number;
    unknown: number;
    followUps: number;
    ratio: number;
};
