import { Button, MenuItem } from '@blueprintjs/core';
import { DateRangeShortcut } from '@blueprintjs/datetime';
import { DateRangeInput3 } from '@blueprintjs/datetime2';
import { MultiSelect, Select } from '@blueprintjs/select';
import { MouseEventHandler } from 'react';

import { classifications, granularities, intervals, pathways, services } from './consts';
import { useFilters } from './FiltersContext';
import { Granularity, MultiPropsType, SinglePropsType } from './types';

export const FiltersMenu = () => {
    const {
        loading,
        dates,
        setDates,
        granularity,
        setGranularity,
        classificationsSelected,
        setClassificationsSelected,
        servicesSelected,
        setServicesSelected,
        pathwaysSelected,
        setPathwaysSelected,
        updateMultiSelectValue,
    } = useFilters();

    const handleDatesChange = (newDates: [Date | null, Date | null]) => {
        setDates(newDates);
    };

    const handleGranularityChange = (newGranularity: string) => {
        setGranularity(newGranularity as Granularity);
    };

    const handlePathwaysChange = (newValue: string) => {
        setPathwaysSelected((current) => updateMultiSelectValue(current, newValue));
    };

    const handleServicesChange = (newValue: string) => {
        setServicesSelected((current) => updateMultiSelectValue(current, newValue));
    };

    const handleClassificationsChange = (newValue: string) => {
        setClassificationsSelected((current) => updateMultiSelectValue(current, newValue));
    };

    const renderSelectItem = (
        value: string,
        handleClick: MouseEventHandler<HTMLElement>,
        prop: SinglePropsType,
    ) => {
        let name = '';
        if (prop === 'granularity') name = granularities[value as Granularity];
        return (
            <MenuItem
                className="reporting__menu-select-item"
                key={value}
                text={name}
                onClick={handleClick}
                roleStructure="listoption"
            />
        );
    };

    const renderMultiSelectItem = (
        value: string,
        handleClick: MouseEventHandler<HTMLElement>,
        prop: MultiPropsType,
    ) => {
        let array: string[] = [];
        if (prop === 'classifications') array = classificationsSelected;
        if (prop === 'services') array = servicesSelected;
        if (prop === 'pathways') array = pathwaysSelected;

        let name = '';
        if (prop === 'classifications') name = classifications[value];
        if (prop === 'services') name = services[value];
        if (prop === 'pathways') name = pathways[value];

        const isSelected = array.includes(value);

        return (
            <MenuItem
                className="reporting__menu-select-item"
                key={value}
                text={name}
                onClick={handleClick}
                roleStructure="listoption"
                selected={isSelected}
            />
        );
    };

    const shortcuts: DateRangeShortcut[] = Object.values(intervals).map(({ name, dates }) => ({
        label: name,
        dateRange: dates,
    }));

    const pathwaysKeys = Object.keys(pathways);
    const servicesKeys = Object.keys(services);
    const classificationKeys = Object.keys(classifications);

    return (
        <menu className="reporting__menu">
            <div className="reporting__menu-filter">
                <span>Start Date</span>
                <DateRangeInput3
                    value={dates}
                    disabled={loading}
                    shortcuts={shortcuts}
                    onChange={handleDatesChange}
                />
            </div>

            <div className="reporting__menu-filter">
                <span>Granularity</span>
                <Select
                    items={Object.keys(granularities)}
                    filterable={false}
                    disabled={loading}
                    itemRenderer={(value, props) =>
                        renderSelectItem(value, props.handleClick, 'granularity')
                    }
                    onItemSelect={handleGranularityChange}
                >
                    <Button
                        style={{ width: '120px' }}
                        disabled={loading}
                        className="reporting__menu-select-button"
                        text={granularities[granularity]}
                        rightIcon="chevron-down"
                    />
                </Select>
            </div>

            {pathwaysKeys.length > 0 && (
                <div className="reporting__menu-filter">
                    <span>Pathway</span>
                    <MultiSelect
                        className="reporting__menu-select-button"
                        disabled={loading}
                        items={pathwaysKeys}
                        itemRenderer={(value, props) =>
                            renderMultiSelectItem(value, props.handleClick, 'pathways')
                        }
                        onItemSelect={handlePathwaysChange}
                        onRemove={(value) => handlePathwaysChange(value)}
                        selectedItems={pathwaysSelected}
                        tagRenderer={(value) => pathways[value] || ''}
                        tagInputProps={{ tagProps: { minimal: true } }}
                    />
                </div>
            )}

            {servicesKeys.length > 0 && (
                <div className="reporting__menu-filter" data-testid="services">
                    <span>Services</span>
                    <MultiSelect
                        className="reporting__menu-select-button"
                        disabled={loading}
                        items={servicesKeys}
                        itemRenderer={(value, props) =>
                            renderMultiSelectItem(value, props.handleClick, 'services')
                        }
                        onItemSelect={(value) => handleServicesChange(value)}
                        onRemove={(value) => handleServicesChange(value)}
                        selectedItems={servicesSelected}
                        tagRenderer={(value) => services[value] || ''}
                        tagInputProps={{ tagProps: { minimal: true } }}
                    />
                </div>
            )}

            {classificationKeys.length > 0 && (
                <div className="reporting__menu-filter">
                    <span>Job Classification</span>
                    <MultiSelect
                        className="reporting__menu-select-button"
                        disabled={loading}
                        items={classificationKeys}
                        itemRenderer={(value, props) =>
                            renderMultiSelectItem(value, props.handleClick, 'classifications')
                        }
                        onItemSelect={(value) => handleClassificationsChange(value)}
                        onRemove={(value) => handleClassificationsChange(value)}
                        selectedItems={classificationsSelected}
                        tagRenderer={(value) => classifications[value] || ''}
                        tagInputProps={{ tagProps: { minimal: true } }}
                    />
                </div>
            )}
        </menu>
    );
};
