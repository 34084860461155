import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Colors,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import {
    classificationsSortOrder,
    fontStyle,
    maxLabelLength,
    maxLabelsCount,
    textColor,
    xAxisHeight,
} from './consts';
import { ChartData } from './types';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Colors,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
);

ChartJS.defaults.color = textColor;
ChartJS.defaults.font = fontStyle;

const totalLabelsPlugin = {
    id: 'totalLabels',
    afterDatasetsDraw(chart: ChartJS): void {
        const { ctx } = chart;

        ctx.save();

        const labels = chart.data.labels as string[] | undefined;

        if (!labels) {
            ctx.restore();
            return;
        }

        if (labels.length < maxLabelsCount) {
            labels.forEach((_, index: number) => {
                const total = chart.data.datasets.reduce(
                    (result, dataset) => result + ((dataset.data[index] as number) || 0),
                    0,
                );

                const meta = chart.getDatasetMeta(chart.data.datasets.length - 1);

                if (meta.data[index] && total > 0) {
                    const { x, y } = meta.data[index].tooltipPosition(false);

                    ctx.fillStyle = textColor;
                    ctx.font = `${fontStyle.weight} ${fontStyle.size}px ${fontStyle.family}`;
                    ctx.textAlign = 'center';

                    ctx.fillText(total.toString(), x, y - 10);
                }
            });
        }

        ctx.restore();
    },
};

const legendMarginPlugin = {
    id: 'legendMargin',
    beforeInit(chart: ChartJS) {
        const originalFit = (chart.legend as any).fit;

        (chart.legend as any).fit = function fit() {
            originalFit.bind(chart.legend)();
            this.height += 20;
        };
    },
};

type BarChartProps = React.FC<{
    chartData: ChartData;
    title: string;
    hideLegend?: boolean;
}>;
export const BarChart: BarChartProps = ({ chartData, title, hideLegend }) => {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    callback: (value: any, index: number): string => {
                        const labels = chartData.labels as string[] | undefined;
                        const label = labels && labels[index] ? labels[index] : String(value);

                        return label.length > maxLabelLength
                            ? label.substring(0, maxLabelLength) + '...'
                            : label;
                    },
                },
                afterFit: (scale: any) => {
                    scale.height = xAxisHeight;
                },
            },
            y: {
                stacked: true,
                beginAtZero: true,
                ticks: {
                    precision: 0,
                },
            },
        },
        plugins: {
            title: {
                display: true,
                text: title,
                font: {
                    size: fontStyle.size * 1.2,
                },
            },
            colors: {
                enabled: true,
            },
            legend: {
                display: !hideLegend,
                labels: {
                    sort: (a: any, b: any) =>
                        classificationsSortOrder.indexOf(a.text) -
                        classificationsSortOrder.indexOf(b.text),
                },
            },
            datalabels: {
                display: false,
            },
        },
    };

    return (
        <Bar data={chartData} options={options} plugins={[totalLabelsPlugin, legendMarginPlugin]} />
    );
};
