import { useQuery } from '@apollo/client';
import { Spinner } from '@blueprintjs/core';
import { useEffect } from 'react';

import { ReportAllJobsDocument, ReportAllJobsQuery } from '../../../__generated__/v2';
import { BarChart } from './BarChart';
import { useFilters } from './FiltersContext';
import { StatsType } from './types';
import { prepareJobsByPathwayData, prepareJobsData, prepareJobsPerServiceData } from './utils';

function JobsReport() {
    const {
        setLoading,
        dates,
        granularity,
        classificationsSelected,
        servicesSelected,
        pathwaysSelected,
    } = useFilters();

    const { data, error, loading } = useQuery<ReportAllJobsQuery>(ReportAllJobsDocument, {
        variables: {
            dateStart: dates[0],
            dateEnd: dates[1],
            classifications: classificationsSelected,
            pathways: pathwaysSelected,
            services: servicesSelected,
        },
        fetchPolicy: 'cache-first',
    });

    // Update loading in context for the filters to use
    useEffect(() => {
        setLoading(loading);
    }, [loading, setLoading]);

    const stats: StatsType = {
        initials: 0,
        directs: 0,
        indirects: 0,
        administratives: 0,
        unknown: 0,
        followUps: 0,
        ratio: 0,
    };

    (data?.reportAllJobs || []).forEach((job) => {
        if (!job.jobClassification) stats.unknown += 1;
        if (job.jobClassification === 'Initial') stats.initials += 1;
        if (job.jobClassification === 'Direct') stats.directs += 1;
        if (job.jobClassification === 'Indirect') stats.indirects += 1;
        if (job.jobClassification === 'Administrative') stats.administratives += 1;
        if (job.serviceId === 'followUp') stats.followUps += 1;
    });
    stats.ratio = stats.initials / (stats.followUps || 1);

    const chartData =
        data && dates[0] && dates[1]
            ? prepareJobsData(data.reportAllJobs, dates[0], dates[1], granularity)
            : null;

    const chartServicesData = data
        ? prepareJobsPerServiceData(data.reportAllJobs, 'service')
        : null;
    const chartAdminTypesData = data
        ? prepareJobsPerServiceData(data.reportAllJobs, 'adminType')
        : null;
    const chartPathwaysData = data ? prepareJobsByPathwayData(data.reportAllJobs) : null;

    return (
        <div className="reporting__content" data-testid="content">
            {loading && (
                <div className="reporting__content-cover">
                    <Spinner />
                </div>
            )}

            {!loading && error && (
                <div className="reporting__content-cover">No data to create the charts.</div>
            )}

            {!loading && !error && chartData && (
                <aside className="reporting__stats">
                    <h2>Summary</h2>
                    <h3 className="reporting__stats-title">Initials</h3>
                    <div className="reporting__stats-value">{stats.initials}</div>
                    <h3 className="reporting__stats-title">Directs</h3>
                    <div className="reporting__stats-value">{stats.directs}</div>
                    <h3 className="reporting__stats-title">Indirects</h3>
                    <div className="reporting__stats-value">{stats.indirects}</div>
                    <h3 className="reporting__stats-title">Administrative</h3>
                    <div className="reporting__stats-value">{stats.administratives}</div>
                    <h3 className="reporting__stats-title">Initials : Follow Ups</h3>
                    <div className="reporting__stats-value">
                        {Math.min(stats.initials, 1)} : {Math.round(stats.ratio * 100) / 100}
                    </div>
                </aside>
            )}
            {!loading && !error && chartData && (
                <main className="reporting__charts">
                    <div className="reporting__charts-main">
                        <BarChart chartData={chartData} title="Jobs Planned" />
                    </div>
                    <div className="reporting__charts-additional">
                        <div className="--pathways">
                            <BarChart chartData={chartPathwaysData!} title="Jobs per Pathway" />
                        </div>
                        <div className="--services">
                            <BarChart
                                chartData={chartServicesData!}
                                title="Jobs per Service"
                                hideLegend
                            />
                        </div>
                        <div className="--admin-types">
                            <BarChart
                                chartData={chartAdminTypesData!}
                                title="Jobs per Admin Type"
                                hideLegend
                            />
                        </div>
                    </div>
                </main>
            )}
        </div>
    );
}

export default JobsReport;
