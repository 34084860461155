import { JobStatus, Service } from '@doc-abode/data-models';
import { isAborted, isArrived, isCompleted } from '@doc-abode/helpers';
import { FormikValues } from 'formik';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import { getDateEndTime, isDisableFormBtn } from '../../../../../helpers/ucr';
import { transformFormikValuesToPatientForWarningValidation } from '../../../../../helpers/ucr/getWarnings';
import { isHcpWorking } from '../../../../../helpers/ucr/isHcpWorking';
import { useHcpSuggestionOptions } from '../../../../../hook/useHcpSuggestionOptions';
import useStores from '../../../../../hook/useStores';
import useVisitWarnings from '../../../../../hook/useVisitWarnings';
import RootStore from '../../../../../stores/RootStore';
import { VisitValuesType } from '../../blocks/panels/VisitDetailsTypes';
import { useWarningMessageViewModel } from '../../hooks/useWarningMessageViewModel';
import { FormMode, FormSteps, getButtonName, warningHighlight } from '../common';
import { CareDetailsType } from './CareDetails';

const requiredFields: Array<keyof FormikValues> = [
    'referralPathway',
    'disposition',
    'visitDate',
    'duration',
];

export const useCareDetailsViewModel = ({
    values,
    loading,
    formMode,
    setFieldValue,
    setValues,
    onNextStep = () => {},
    onSaveForm = () => {},
}: CareDetailsType) => {
    const {
        RootStore: {
            ucrStore: { hourStart, updateWarnings },
            schedulesStore: { getSchedulesByDate },
            configStore: { pathways },
        },
    } = useStores<{ RootStore: RootStore }>();
    const [isContinueBtnClicked, setIsContinueBtnClicked] = useState(false);

    const [showUnavailableHcp, setShowUnavailableHcp] = useState(false);
    const [showUnavailableBuddyHcp, setShowUnavailableBuddyHcp] = useState(false);

    useEffect(() => {
        getSchedulesByDate(moment(values.visitDate).format('YYYY-MM-DD'));
    }, [values.visitDate, getSchedulesByDate]);

    const hcpSuggestionOptions = useHcpSuggestionOptions(values as VisitValuesType);

    // Prepare data for HCP1
    const statusOfJobHcp1 = values.jobStatus as JobStatus | undefined;

    const isHcp1Arrived = isArrived({ jobStatus: statusOfJobHcp1 });
    const isHcp1Completed = isCompleted({ jobStatus: statusOfJobHcp1 });
    const isHcp1Aborted = isAborted({ jobStatus: statusOfJobHcp1 });

    // Prepare data for HCP2
    const statusOfJobHcp2 = values.buddyJobStatus as JobStatus | undefined;
    const isHcp2Completed = isCompleted({ jobStatus: statusOfJobHcp2 });
    const isHcp2Aborted = isAborted({ jobStatus: statusOfJobHcp2 });

    const isErrors = isDisableFormBtn(values, requiredFields);
    const buttonName = getButtonName(formMode, FormSteps.CARE);
    const disableStaff = formMode === FormMode.EDIT_VISIT && values.staffRequired === 2;

    const [staffRequirements, setStaffRequirement] = useState(values.staffRequired);

    const selectedPathway = pathways.find(
        (pathway: any) => pathway.value === values.referralPathway,
    );
    const serviceList =
        selectedPathway?.services
            .slice()
            .sort((serviceA: Service, serviceB: Service) =>
                serviceA.label.localeCompare(serviceB.label),
            ) || [];

    const disabledPathwayValue = !!pathways.find(
        ({ value, enabled }: { value: any; enabled: any }) =>
            value === values.referralPathway && !enabled,
    );

    const disabledServiceValue =
        selectedPathway?.services.find(
            ({ value, enabled }: { value: any; enabled: any }) =>
                value === values.disposition && !enabled,
        ) ||
        !selectedPathway?.services.find(
            ({ value }: { value: any }) => value === values.disposition,
        );

    const [highlightedWarnings, setHighlightedWarnings] = useState<any>({});

    const onSubmit = () => {
        if (!isErrors) {
            // would this be better as if (formMode === FormMode.EDIT_VISIT) first
            // includes or if we do above no need for this check unless
            // we actually need it to be in these form modes and we are planning on having some other form modes in the future
            if (
                formMode === FormMode.DEFAULT ||
                formMode === FormMode.ADD_VISIT ||
                formMode === FormMode.FOLLOW_UP
            ) {
                onNextStep(FormSteps.REVIEW, FormSteps.CARE);
            } else if (formMode === FormMode.EDIT_VISIT) {
                onSaveForm(FormSteps.CARE);
            }
        } else {
            // When we do have errors.  what will this end up doing -> setIsContinueBtnClicked
            setIsContinueBtnClicked(true);
        }
    };

    const MAXDATE = moment().add(1, 'year').toDate();
    const MINTIME = useMemo(() => moment().startOf('day').hour(hourStart).toDate(), [hourStart]);

    const defaultStartTime = moment().startOf('minutes').toDate();

    useEffect(() => {
        let hcpJobStatus: JobStatus | undefined = undefined;
        let buddyJobStatus: JobStatus | undefined = undefined;
        let disabledPathwayValueHide: boolean = false;
        let disabledServiceValueHide: boolean = false;

        if (formMode === FormMode.FOLLOW_UP && !values.hcpId) {
            hcpJobStatus = JobStatus.PENDING;
        }
        if (formMode === FormMode.FOLLOW_UP && values.hcpId && !isHcp1Aborted) {
            hcpJobStatus = JobStatus.ACCEPTED;
        }

        if (formMode === FormMode.FOLLOW_UP && !values.buddyId) {
            buddyJobStatus = JobStatus.PENDING;
        }
        if (formMode === FormMode.FOLLOW_UP && values.buddyId && !isHcp2Aborted) {
            buddyJobStatus = JobStatus.ACCEPTED;
        }

        if (
            formMode === FormMode.EDIT_VISIT &&
            values.buddyId &&
            values.staffRequired === 2 &&
            values.buddyJobStatus === JobStatus.PENDING
        ) {
            buddyJobStatus = JobStatus.ACCEPTED;
        }
        if (
            formMode === FormMode.EDIT_VISIT &&
            values.hcpId &&
            values.jobStatus === JobStatus.PENDING
        ) {
            hcpJobStatus = JobStatus.ACCEPTED;
        }

        // VSU-1093 Creating follow ups from or editing now archived pathways need to set a new value
        if (
            (formMode === FormMode.FOLLOW_UP || formMode === FormMode.EDIT_VISIT) &&
            disabledPathwayValue
        ) {
            disabledPathwayValueHide = true;
        }

        // VSU-1294 Creating follow ups from or editing now archived services need to set a new value
        if (
            (formMode === FormMode.FOLLOW_UP || formMode === FormMode.EDIT_VISIT) &&
            disabledServiceValue
        ) {
            disabledServiceValueHide = true;
        }

        if (values.hcpId && !isHcpWorking(hcpSuggestionOptions, values.hcpId)) {
            setShowUnavailableHcp(true);
        }

        if (values.buddyId && !isHcpWorking(hcpSuggestionOptions, values.buddyId)) {
            setShowUnavailableBuddyHcp(true);
        }

        let changes: Boolean = false;
        if (disabledServiceValueHide) {
            values.disposition = undefined;
            changes = true;
        }
        if (disabledPathwayValueHide) {
            values.referralPathway = undefined;
            changes = true;
        }
        if (hcpJobStatus) {
            values.jobStatus = hcpJobStatus;
            changes = true;
        }
        if (buddyJobStatus) {
            values.buddyJobStatus = buddyJobStatus;
            changes = true;
        }

        // if a hcp is assigned or a buddy is assigned and there no start time default to now.
        if ((values.hcpId || values.buddyId) && !values.startTime) {
            values.startTime = moment().toDate();
            changes = true;
        }

        if (changes) {
            setValues(values, false);
        }
    }, [
        formMode,
        setValues,
        values,
        values.hcpId,
        values.duration,
        values.startTime,
        isHcp1Aborted,
        values.buddyId,
        loading,
        isHcp1Arrived,
        isHcp2Aborted,
        values.staffRequired,
        values.jobStatus,
        values.buddyJobStatus,
        disabledPathwayValue,
        hcpSuggestionOptions,
        disabledServiceValue,
    ]);

    useEffect(() => {
        const endTime = values.startTime
            ? getDateEndTime(values.startTime, values.duration)
            : moment().startOf('day').toDate();

        setFieldValue('endTime', endTime);
    }, [values.startTime, values.duration, setFieldValue]);

    useEffect(() => {
        if (staffRequirements !== values.staffRequired) {
            if (formMode === FormMode.EDIT_VISIT && values.buddyId && values.staffRequired === 2) {
                setFieldValue('buddyJobStatus', JobStatus.ACCEPTED);
            }
            if (formMode === FormMode.EDIT_VISIT && !values.buddyId && values.staffRequired === 2) {
                setFieldValue('buddyJobStatus', JobStatus.PENDING);
            }
        }
    }, [
        formMode,
        setFieldValue,
        values.staffRequired,
        values.buddyId,
        staffRequirements,
        setStaffRequirement,
    ]);

    // Update (BE) warnings for the current job
    const visitWarnings = useVisitWarnings(values as VisitValuesType);

    useEffect(() => {
        updateWarnings(values.id, visitWarnings || []);
    }, [
        values.hcpId,
        values.buddyId,
        updateWarnings,
        values.id,
        values.visitDate,
        values.startTime,
        values.duration,
        visitWarnings,
    ]);

    // Determine warning messages to show
    const tmpJob = transformFormikValuesToPatientForWarningValidation({ values, isAdmin: false });

    const { warningMessages, hasWarningMessages } = useWarningMessageViewModel({
        job: tmpJob,
        sectionShown: FormSteps.CARE,
    });

    useEffect(() => {
        const newWarnings = warningHighlight({
            warnings: warningMessages,
            values: values,
        });

        setHighlightedWarnings((prev: any) => {
            return JSON.stringify(prev) !== JSON.stringify(newWarnings) ? newWarnings : prev;
        });
    }, [values, warningMessages]);

    // Consolidated logic to drive disabled state of various screen elements
    const isHcp1SelectorDisabled = loading || isHcp1Completed || isHcp1Aborted;
    const isHcp2SelectorDisabled = loading || isHcp2Completed || isHcp2Aborted;
    const isStaffRequiredSelectorDisabled =
        loading || isHcp1Arrived || isHcp1Aborted || isHcp2Aborted || disableStaff;
    const isVisitDateDisabled = loading || isHcp1Arrived;

    return {
        buttonName,
        defaultStartTime,
        disabledPathwayValue,
        MAXDATE,
        MINTIME,
        isContinueBtnClicked,
        pathways,
        serviceList,
        highlightedWarnings,
        setStaffRequirement,
        setShowUnavailableBuddyHcp,
        setShowUnavailableHcp,
        onSubmit,
        warningMessages,
        hasWarningMessages,
        showUnavailableBuddyHcp,
        showUnavailableHcp,
        isHcp1SelectorDisabled,
        isHcp2SelectorDisabled,
        isStaffRequiredSelectorDisabled,
        isVisitDateDisabled,
    };
};
