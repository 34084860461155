import { Form, FormikContextType, FormikValues, useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import { FC, useCallback, useState } from 'react';

import { Accordion, AccordionColors, AccordionTab } from '../../../../v2/components';
import { FormMode, FormSteps } from '../common';
import { PullFromReferralForm } from '../DetailsFromReferral';
import { PatientTab } from '../PatientFormTab';
import ActivityDetails from './ActivityDetails';
import { getStepSchema } from './validation';

export interface IProps {
    step: FormSteps;
    loading: boolean;
    handleSubmit: (values: FormikValues) => Promise<void>;
    formMode: FormMode;
    patientFieldsRequired: boolean;
    postVisitNotesRequired: boolean;
    handleChangePatientRequired: (isRequired: boolean) => void;
}

const AddVisitForm: FC<IProps> = ({
    step,
    loading,
    handleSubmit,
    formMode,
    patientFieldsRequired,
    postVisitNotesRequired,
    handleChangePatientRequired,
}) => {
    const [currentStep, setCurrentStep] = useState<FormSteps>(step);
    const [stepsCompleted, setStepsCompleted] = useState<string[]>([]);

    const { values, setFieldValue, setTouched, setValues }: FormikContextType<FormikValues> =
        useFormikContext();

    const onNextStep = useCallback(
        (nextStep: FormSteps, prevStep: FormSteps) => {
            const stepSchema = getStepSchema(prevStep, {
                patientFieldsRequired,
                postVisitNotesRequired,
            });
            const stepFields = Object.keys(stepSchema.fields).reduce(
                (fields, field) => ({ ...fields, [field]: true }),
                {},
            );

            setTouched(stepFields, true);

            try {
                if (!stepSchema.validateSync(values)) return;

                setStepsCompleted([...stepsCompleted, prevStep]);

                if (nextStep === FormSteps.REVIEW) {
                    handleSubmit(values);
                } else {
                    setCurrentStep(nextStep);
                }
            } catch (err: any) {
                console.log('Validation error:', err.message);
            }
        },
        [
            patientFieldsRequired,
            postVisitNotesRequired,
            setTouched,
            stepsCompleted,
            handleSubmit,
            values,
        ],
    );

    const onSaveForm = useCallback(
        (step: FormSteps) => {
            const stepSchema = getStepSchema(step, {
                patientFieldsRequired,
                postVisitNotesRequired,
            });
            const stepFields = Object.keys(stepSchema.fields).reduce(
                (fields, field) => ({ ...fields, [field]: true }),
                {},
            );

            setTouched(stepFields, true);

            try {
                const stepIsValid = stepSchema.validateSync(values);
                if (stepIsValid) {
                    handleSubmit(values);
                }
            } catch (err: any) {
                console.error('Validation error:', err.message);
            }
        },
        [handleSubmit, patientFieldsRequired, postVisitNotesRequired, setTouched, values],
    );

    const onPatientDetailsNextStep = useCallback(() => {
        if (formMode === FormMode.DEFAULT || formMode === FormMode.FOLLOW_UP) {
            onNextStep(FormSteps.ACTIVITY, FormSteps.PATIENT);
        } else if (formMode === FormMode.ADD_VISIT) {
            onNextStep(FormSteps.REVIEW, FormSteps.PATIENT);
        }
    }, [formMode, onNextStep]);

    const activityStepColor =
        currentStep === FormSteps.ACTIVITY ? AccordionColors.PINK : AccordionColors.GREY;

    return (
        <Form>
            <Accordion>
                <PatientTab
                    stepsCompleted={stepsCompleted}
                    currentStep={currentStep}
                    formMode={formMode}
                    loading={loading}
                    onClick={() => setCurrentStep(FormSteps.PATIENT)}
                    onNextStep={onPatientDetailsNextStep}
                    onSaveForm={onSaveForm}
                    patientDetailsRequired={patientFieldsRequired}
                    handleChangePatientRequired={handleChangePatientRequired}
                />
                <AccordionTab
                    name="Activity"
                    title="Activity details"
                    color={
                        stepsCompleted.includes(FormSteps.ACTIVITY) &&
                        currentStep !== FormSteps.ACTIVITY
                            ? AccordionColors.BLUE
                            : activityStepColor
                    }
                    open={currentStep === FormSteps.ACTIVITY}
                    disabled={!stepsCompleted.includes(FormSteps.PATIENT)}
                >
                    <ActivityDetails
                        values={values}
                        loading={loading}
                        onNextStep={onNextStep}
                        setFieldValue={setFieldValue}
                        setValues={setValues}
                        formMode={formMode}
                        onSaveForm={handleSubmit}
                    />
                </AccordionTab>
            </Accordion>
            <PullFromReferralForm isAdminTime />
        </Form>
    );
};

export default observer(AddVisitForm);
