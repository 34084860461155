import './assets/index.scss';
import '@aws-amplify/ui-react/styles.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { configure } from 'mobx';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';

import { AppShell } from './components/AppShell';
import config from './config';
import { initSentry } from './config/sentry';
import reportWebVitals from './reportWebVitals';

const {
    ga: { measurementId },
} = config();

if (measurementId) {
    ReactGA.initialize(measurementId);
    ReactGA.send('pageview');
}

initSentry();
configure({ enforceActions: 'never' });
library.add(faEllipsisV);

const Root = (
    <React.StrictMode>
        <AppShell />
    </React.StrictMode>
);

ReactDOM.render(Root, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

console.log('Build version 4.6.0 generated at: Thu Apr 10 2025 13:40:33 GMT+0000 (Coordinated Universal Time)');
