import { NavLink, Redirect, Switch } from 'react-router-dom';

import { SentryRoute } from '../../../helpers/sentryRoute';
import useStores from '../../../hook/useStores';
import RootStore from '../../../stores/RootStore';
import { CompletedJobsReport, JobsReport } from '../../pages/reporting';
import { FiltersProvider } from '../../pages/reporting/FiltersContext';
import { FiltersMenu } from '../../pages/reporting/FiltersMenu';

export default function Reporting() {
    const {
        RootStore: {
            configStore: { isFeatureEnabled },
        },
    } = useStores<{ RootStore: RootStore }>();

    if (!isFeatureEnabled('reporting')) {
        return <Redirect to="/page-not-found" />;
    }

    const renderRoutesWithContext = () => (
        <Switch>
            <Redirect exact from="/reporting" to="/reporting/planned-jobs" />
            <SentryRoute path="/reporting/planned-jobs" component={JobsReport} />
            <SentryRoute path="/reporting/completed-jobs" component={CompletedJobsReport} />
        </Switch>
    );

    return (
        <section className="reporting">
            <ul className="reporting__links">
                <li>
                    <NavLink
                        className="reporting__link"
                        activeClassName="reporting__link--active"
                        to="/reporting/planned-jobs"
                    >
                        Planned Jobs
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className="reporting__link"
                        activeClassName="reporting__link--active"
                        to="/reporting/completed-jobs"
                    >
                        Planned vs Completed Jobs
                    </NavLink>
                </li>
            </ul>

            <section className="reporting__main">
                <FiltersProvider>
                    <FiltersMenu />
                    {renderRoutesWithContext()}
                </FiltersProvider>
            </section>
        </section>
    );
}
