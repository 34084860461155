import { DateRange } from '@blueprintjs/datetime2';
import moment from 'moment';

import { Granularity, TimeInterval } from './types';

// ChartJS requires the styles set explicitely to canvas context.
export const fontStyle = {
    family: '"Mulish", sans-serif',
    size: 12,
    weight: 400,
};
export const textColor = '#546e7a';
export const maxLabelsCount = 100;
export const maxLabelLength = 8;
export const xAxisHeight = 64;

export const chartPalette = ['#fda8b6', '#f3af56', '#f7e07f', '#b1d2b8', '#b0b0b0'];

export const classificationsSortOrder = [
    'Initial',
    'Direct',
    'Indirect',
    'Administrative',
    'No Classification',
];

// Classifications are "jobClassification" column from connected LookupValues table
export const classifications: Record<string, string> = {};

// Services are "services" column from Visit table
export const services: Record<string, string> = {};

// Pathways are "referralPathway" from Admission table
export const pathways: Record<string, string> = {};

export const granularities: Record<Granularity, string> = {
    [Granularity.daily]: 'Daily',
    [Granularity.weekly]: 'Weekly',
    [Granularity.monthly]: 'Monthly',
};

const tomorrow = moment().add(1, 'day').startOf('day').toDate();
export const intervals: Record<TimeInterval, { name: string; dates: DateRange }> = {
    [TimeInterval.allTime]: { name: 'All Time', dates: [null, null] },
    [TimeInterval.today]: { name: 'Today', dates: [moment().startOf('day').toDate(), tomorrow] },
    [TimeInterval.yesterday]: {
        name: 'Yesterday',
        dates: [
            moment().subtract(1, 'day').startOf('day').toDate(),
            moment().startOf('day').toDate(),
        ],
    },
    [TimeInterval.thisWeek]: {
        name: 'This Week',
        dates: [moment().startOf('isoWeek').toDate(), tomorrow],
    },
    [TimeInterval.thisMonth]: {
        name: 'This Month',
        dates: [moment().startOf('month').toDate(), tomorrow],
    },
    [TimeInterval.thisYear]: {
        name: 'This Year',
        dates: [moment().startOf('year').toDate(), tomorrow],
    },
    [TimeInterval.lastWeek]: {
        name: 'Last Week',
        dates: [
            moment().subtract(1, 'week').startOf('isoWeek').toDate(),
            moment().startOf('isoWeek').toDate(),
        ],
    },
    [TimeInterval.lastMonth]: {
        name: 'Last Month',
        dates: [
            moment().subtract(1, 'month').startOf('month').toDate(),
            moment().startOf('month').toDate(),
        ],
    },
    [TimeInterval.last3Month]: {
        name: 'Last 3 Months',
        dates: [
            moment().subtract(3, 'month').startOf('month').toDate(),
            moment().startOf('month').toDate(),
        ],
    },
    [TimeInterval.lastYear]: {
        name: 'Last Year',
        dates: [
            moment().subtract(1, 'year').startOf('year').toDate(),
            moment().startOf('year').toDate(),
        ],
    },
    [TimeInterval.financialYear]: {
        name: 'Financial Year',
        dates: (() => {
            const currentYear = moment().year() - 1;
            let start = moment([currentYear, 3, 1]).startOf('day');

            if (moment().isBefore(start)) {
                start = start.subtract(1, 'year');
            }

            const end = start.clone().add(1, 'year').endOf('day');

            return [start.toDate(), end.toDate()];
        })(),
    },
};
