import { useQuery } from '@apollo/client';
import { Spinner } from '@blueprintjs/core';
import { DateRange } from '@blueprintjs/datetime2';
import moment from 'moment';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { ReportAllJobsDocument, ReportAllJobsQuery } from '../../../__generated__/v2';
import { classifications, intervals, pathways, services } from './consts';
import { Granularity, TimeInterval } from './types';

type FiltersContextType = {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    dates: DateRange;
    setDates: React.Dispatch<React.SetStateAction<DateRange>>;
    granularity: Granularity;
    setGranularity: React.Dispatch<React.SetStateAction<Granularity>>;
    classificationsSelected: string[];
    setClassificationsSelected: React.Dispatch<React.SetStateAction<string[]>>;
    servicesSelected: string[];
    setServicesSelected: React.Dispatch<React.SetStateAction<string[]>>;
    pathwaysSelected: string[];
    setPathwaysSelected: React.Dispatch<React.SetStateAction<string[]>>;
    updateMultiSelectValue: (array: string[], newValue: string) => string[];
};

const FiltersContext = createContext<FiltersContextType | undefined>(undefined);

export const FiltersProvider = ({ children }: { children: ReactNode }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [dates, setDates] = useState<DateRange>([null, null]);
    const [granularity, setGranularity] = useState<Granularity>('monthly' as Granularity);
    const [classificationsSelected, setClassificationsSelected] = useState<string[]>([]);
    const [servicesSelected, setServicesSelected] = useState<string[]>([]);
    const [pathwaysSelected, setPathwaysSelected] = useState<string[]>([]);

    const updateMultiSelectValue = (array: string[], newValue: string) =>
        array.includes(newValue)
            ? array.filter((value) => value !== newValue)
            : [...array, newValue];

    const { data, loading: queryLoading } = useQuery<ReportAllJobsQuery>(ReportAllJobsDocument, {
        fetchPolicy: 'cache-first',
    });

    useEffect(() => {
        if (!data) return;

        // If there are no dates, then this is the first request,
        // we should populate the dates and filters
        if (!dates[0] || !dates[1]) {
            const timestamps = data.reportAllJobs.map((job) => new Date(job.plannedDate).getTime());
            const minDate = new Date(Math.min(...timestamps));
            const maxDate = moment(Math.max(...timestamps))
                .add(1, 'day')
                .toDate();

            setDates([minDate, maxDate]);
            intervals[TimeInterval.allTime].dates = [minDate, maxDate];

            data.reportAllJobs.forEach((job) => {
                if (job.jobClassification && !classifications[job.jobClassification]) {
                    classifications[job.jobClassification] = job.jobClassification;
                }
                if (job.pathwayId && job.pathwayName && !pathways[job.pathwayId]) {
                    pathways[job.pathwayId] = job.pathwayName;
                }
                if (job.serviceId && job.serviceName && !services[job.serviceId]) {
                    services[job.serviceId] = job.serviceName;
                }
            });
        }
    }, [data, dates, setDates]);

    return (
        <FiltersContext.Provider
            value={{
                loading,
                setLoading,
                dates,
                setDates,
                granularity,
                setGranularity,
                classificationsSelected,
                setClassificationsSelected,
                servicesSelected,
                setServicesSelected,
                pathwaysSelected,
                setPathwaysSelected,
                updateMultiSelectValue,
            }}
        >
            {queryLoading && (
                <div className="reporting__content">
                    <div className="reporting__content-cover">
                        <Spinner />
                    </div>
                </div>
            )}
            {!queryLoading && children}
        </FiltersContext.Provider>
    );
};

export const useFilters = () => {
    const context = useContext(FiltersContext);
    if (!context) {
        throw new Error('useFilters must be used within a FiltersProvider');
    }
    return context;
};
